import { FC, useEffect, useState } from "react";
import AppLayout from "../../components/layout/app-layout/app-layout";
import Tabs from "../../components/shared/Tabs";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Card from "../../components/shared/Card/card";
import styles from "./page-marketing.module.scss";
import ReceivedBidRequestStats from "../page-bid-request-list/ReceivedRequestsStats";
import { ServiceQuotation } from "../../services/service-quotation";
import { Loading } from "../../components/shared";
import { InvoiceInterval, Request } from "../page-bid-request-list/types";
import { ServiceSupplier } from "../../services/service-supplier";
import TitleWrapper from "../../components/shared/TitleWrapper/TitleWrapper";
import BtButton from "../bt-button/bt-button";
import { serviceAreas } from "../../services/service-areas";
import { serviceAdApplication } from "../../services/service-ad-application";
import _ from "lodash";
import { SelectorType } from "../../components/shared/Selector/Selector";
import { NotificationManager } from "react-notifications";
import * as Sentry from "@sentry/react";
import { AdModal } from "./ad-modal";
import { AdApplicationStatus, AdApplicationType } from "../../util/appEnum";
import Badge from "../../components/shared/Badge/Badge";
import NewsCard from "../page-dashboard-V2/NewsCard/NewsCard";
import serviceSales, { Subscription } from "../../services/service-sales";
import TooltipContainer from "../../components/shared/TooltipContainer/TooltipContainer";
import appState from "../../state/AppStateContainer";

type AdInfoType = {
  ads?: {
    data: {
      _id: string,
      startDate?: string,
      endDate?: string,
      regions?: string[],
      areas?: string[],
    }[],
    earliestStartDateOfAnAd?: string,
  },
  isFeatured: boolean,
  featuredInfo?: {
    url: string,
    image: string,
    title: string,
    description: string,
    startDate: Date,
    endDate: Date,
  },
  addedSuppliers?: {
    name: string,
    createdAt: string,
  }[],
  addedFeaturedSuppliers?: {
    name: string,
    createdAt: string,
  }[],
  supplierId: string,
}

export type AdApplication = {
  organizationNumber?: string,
  errors?: {[x: string]: string},
  startDate?: Date,
  areas?: {id: string, label: string, value: string}[],
  regions?: SelectorType[],
  title?: string,
  url?: string,
  image?: {
    file: string,
  } | File,
  description?: string,
  applicationType?: AdApplicationType,
  invoiceInterval?: InvoiceInterval,
  status?: AdApplicationStatus,
  rejectionReason?: string,
  invoiceEmail?: string,
  invoiceRef?: string,
}

type Params = {};
type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
}

const formatDate = (date: string | Date | undefined) => {
  return date && moment(date).locale('sv').format('D MMMM YYYY');
};

const PageMarketing: FC<Props> = ({ history }) => {
  history;
  const [selectedTab, setSelectedTab] = useState(0);
  const [requests, setRequests] = useState<Request[]>([]);
  const [loading, setLoading] = useState(true);
  const [adInfo, setAdInfo] = useState<AdInfoType>();
  const [adApplication, setAdApplication] = useState<AdApplication>();
  const [areas, setAreas] = useState<{id: string, label: string, value: string}[]>([]);
  const [isApplicationModalVisible, setIsApplicationModalVisible] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);
  const [adApplications, setAdApplications] = useState<AdApplication[]>([]);
  const [allQuotationsSubscription, setAllQuotationsSubscription] = useState<Subscription>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await ServiceQuotation.fetchAllReceived();
      setRequests(response.data);

      const adInfo = await ServiceSupplier.fetchRecommendedSupplierAdInfo();
      setAdInfo(adInfo);

      const adApplications = await serviceAdApplication.fetchLocalAdApplications();
      setAdApplications(adApplications);
      const allQuotationsSubscription = await serviceSales.fetchAllQuotationsSubscription();
      setAllQuotationsSubscription(allQuotationsSubscription);

      const areasData: {_id: string, name: string}[] = await serviceAreas.fetchSuperAdminAreas();
      const areas: {id: string, label: string, value: string}[] = areasData.map(item => ({ id: item._id, label: item.name, value: item.name }));
      
      setAreas(areas);

      setLoading(false);
    })();
  }, []);

  const submitRecommendedSupplierAd = async () => {
    setSubmitting(true);
    try {
      await serviceAdApplication.createRecommendedAdApplication({
        ad: {
          startDate: adApplication?.startDate!,
          regions: (adApplication?.regions ?? []).map(x => x?.value ?? '').filter(x => x),
          areas: (adApplication?.areas ?? []).map(x => x?.id ?? '').filter(x => x),
        },
        invoiceInterval: adApplication?.invoiceInterval!,
        invoiceEmail: adApplication?.invoiceEmail!,
        invoiceRef: adApplication?.invoiceRef!,
      });
      setAdApplication(undefined);
      setIsApplicationModalVisible(false);
      const adApplications = await serviceAdApplication.fetchLocalAdApplications();
      setAdApplications(adApplications);
      await appState.fetchOrganizations();
      const allQuotationsSubscription = await serviceSales.fetchAllQuotationsSubscription();
      setAllQuotationsSubscription(allQuotationsSubscription);
      
      NotificationManager.success('Din ansökan har lämnats', 'Ansökt');
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      setSubmitting(false);
    }
  };

  const submitFeaturedSupplierAd = async () => {
    setSubmitting(true);
    try {
      await serviceAdApplication.createFeaturedAdApplication({
        featuredInfo: {
          startDate: adApplication?.startDate!,
          url: adApplication?.url!,
          title: adApplication?.title!,
          image: (adApplication?.image! as File),
          description: adApplication?.description!,
        },
        invoiceInterval: adApplication?.invoiceInterval!,
        invoiceEmail: adApplication?.invoiceEmail!,
        invoiceRef: adApplication?.invoiceRef!,
      });
      setAdApplication(undefined);
      setIsApplicationModalVisible(false);
      const adApplications = await serviceAdApplication.fetchLocalAdApplications();
      setAdApplications(adApplications);
      await appState.fetchOrganizations();
      const allQuotationsSubscription = await serviceSales.fetchAllQuotationsSubscription();
      setAllQuotationsSubscription(allQuotationsSubscription);
      NotificationManager.success('Din ansökan har lämnats', 'Ansökt');
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      setSubmitting(false);
    }
  };

  const lastRecommendedApplication = adApplications?.find(x => x?.applicationType === AdApplicationType.RECOMMENDED);
  const isRecommendedApplicationPending = lastRecommendedApplication?.status === AdApplicationStatus.PENDING;
  const lastFeaturedApplication = adApplications?.find(x => x?.applicationType === AdApplicationType.FEATURED);
  const isFeaturedApplicationPending = lastFeaturedApplication?.status === AdApplicationStatus.PENDING;

  type AdApplicationStatusCardProps = {
    isPending: boolean,
    applicationType: AdApplicationType,
    rejectionReason: string,
  };

  const ApplicationStatusCard: FC<AdApplicationStatusCardProps> = ({ isPending, rejectionReason }) => {
    return (
      <Card title={
        <span style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <span>Ansökningsstatus: {isPending ? "pågående" : "nekad"}</span>
        </span>
      }>
        {isPending ? (
          <>
            <div style={{ display: 'grid', marginTop: '8px', fontSize: '14px', color: 'var(--gray-700)' }}>
              <span>Tack för din ansökan.</span>
              <span>Vi granskar ansökan och återkommer så snart som möjligt.</span>
              <span>Räkna med svar inom två vardagar.</span>
            </div>

            <p style={{ fontSize: '14px', color: 'var(--gray-700)' }}>Om du vill avbryta eller ändra din ansökan, kontakta support@accurator.se.</p>
          </>
        ) : (
          <>
            <div style={{ display: 'grid', marginTop: '8px', fontSize: '14px', color: 'var(--gray-700)' }}>
              <span>Din ansökan har nekats.</span>
              {rejectionReason && <span>Anledning: {rejectionReason}</span>}
            </div>

            <p style={{ fontSize: '14px', color: 'var(--gray-700)' }}>Kontakta oss om du vill ansöka igen.</p>
          </>
        )} 
      </Card>
    )
  }

  const hasActiveRecommendedAd = adInfo?.ads?.data && adInfo?.ads?.data?.length > 0;
  const hasActiveFeaturedAd = adInfo?.isFeatured;

  const tabs = ["Prio-leverantör", "Annonsera på startsidan"];
  // /@accurator\.se$/.test(appState.getUserEmail())

  return (
    <AppLayout title="Marknadsföring">
      <div style={{ margin: "32px" }}>
        <div className="tab-container">
          <Tabs
            tabs={tabs}
            selected={selectedTab}
            onChange={setSelectedTab}
          />
          <div className="tab-actions">
          </div>
        </div>
        {loading && <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}><Loading type='inline' /></div>}
        {selectedTab === 0 && !loading && (
          <>
            {hasActiveRecommendedAd &&
              <Card title="Aktiva annonser" className={styles.activeRecommendedAds}>
                {adInfo.ads?.data.map(ad => {
                  return (
                    <div className={styles.activeRecommendedAdsItem} key={ad._id}>
                      <p>Datum</p>
                      <span>{formatDate(ad.startDate)} - {formatDate(ad.endDate)}</span>
                      <p>Län</p>
                      <div style={{ display: 'flex', gap: '8px', flexWrap: "wrap" }}>{ad.regions?.map(region => <Badge key={region}>{region}</Badge>)}</div>
                      <p>Yrkeskategorier</p>
                      <div style={{ display: 'flex', gap: '8px', flexWrap: "wrap" }}>{ad.areas?.map(area => <Badge key={area}>{area}</Badge>)}</div>
                    </div>
                  )
                })}
              </Card>
            }
            {adInfo?.ads?.earliestStartDateOfAnAd && (
              <TitleWrapper title="Statistik">
                <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                  {adInfo?.ads?.earliestStartDateOfAnAd && (
                    <ReceivedBidRequestStats requests={requests} adStartDate={moment(adInfo?.ads?.earliestStartDateOfAnAd).toDate()} />
                  )}
                </div>
              </TitleWrapper>
            )}
            {(
              <>
                {!hasActiveRecommendedAd && !lastRecommendedApplication && (
                  <Card className={styles.card}>
                    <h3>Bli Accurator Prio-leverantör</h3>
                    <p><b>Kostar det att använda Accurator?</b></p>
                    <p>Att använda Accurator som leverantör är helt kostnadsfritt. Däremot är "Accurator Prio" en premiumtjänst som erbjuder ytterligare exponering genom att presentera ert företag i ett särskilt register för byggbolag som ni kanske inte vanligtvis får förfrågningar från. Ni kan se en lista över några av de byggbolag som använder Accurator längst ner på denna sida.</p>

                    <p>Att skapa ett leverantörskonto är inte nödvändigt för att använda Accurator som leverantör. Väljer man att inte skapa ett konto, kan ni fortsätta att använda er vanliga e-postinkorg för kommunikation via vår portal.</p>

                    <h3>Utöka ditt nätverk och ta emot fler anbudsförfrågningar som Accurator Prio-leverantör</h3>
                    <ul role="list" className={styles.list}>
                      <li><b>Utökad exponering:</b> Som rekommenderad Accurator Prio-leverantör får du möjligheten att visa upp din information, produkter och referenser i ett särskilt register som används av över 800 inköpare runt om i Sverige.</li>
                      <li><b>Detaljerad presentation:</b> Inkludera omfattande beskrivningar om ditt företag, produktblad, försäkringsuppgifter, certifikat, kundomdömen, och direktkontakt till din föredragna kontaktperson.</li>
                      <li><b>Exklusivitet och ökade möjligheter:</b> Vi begränsar antalet leverantörer inom varje kategori. Detta för att göra erbjudandet mer exklusivt och öka antalet anbudsförfrågningar ni kan få.</li>
                      <li><b>Effektiv kommunikation:</b> Slipp onödiga samtal med inköpare vid olämpliga tillfällen. På Accurator matchas du med skarpa förfrågningar som passar din profil. Du blir en presenterad leverantör för inköpare varje gång de har en förfrågan som matchar er yrkeskategori.</li>
                      <li><b>Kontroll över din synlighet:</b> Välj vilka byggbolag du vill synas för och hur mycket information du delar med dig. På Accurator har du full kontroll över din synlighet och hur ditt varumärke presenteras.</li>
                    </ul>

                    <h3>Byggbolag som använder Accurator</h3>
                    <div role="list" className={styles.c_logo_grid_list}>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img alt="VANERAB" src="https://cdn.prod.website-files.com/60cb60c79838d8d947708ce8/6790ed5602067ab9a273d245_vanergrey.png" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img alt="stenmarksbyggiskaraborg" src="https://cdn.prod.website-files.com/60cb60c79838d8d947708ce8/678f745eb4a2a3a0ff594d02_stenmarkslogogrey.png" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img alt="Mälarö fastigheter" src="https://cdn.prod.website-files.com/60cb60c79838d8d947708ce8/675968c15af970fd72c15a45_cropped-MA%CC%88LARO%CC%88-FASTIGHETS_WHITE%201.png" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img alt="HFFBYGGPARTNER" src="https://cdn.prod.website-files.com/60cb60c79838d8d947708ce8/6759684ad89662b2202ae093_4c67246e-86bb-43b3-b8e7-f899ffcf4f6f%201.png" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://cdn.prod.website-files.com/60cb60c79838d8d947708ce8/6679433e7b8a37451198a4ba_63b4259f0382a1443ed4180e_contractor.png" loading="eager" alt="Contractor" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://cdn.prod.website-files.com/60cb60c79838d8d947708ce8/6679349f350a0ee5392ccafb_Anlab-Logo%20111.png" loading="eager" alt="Anlab" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://cdn.prod.website-files.com/60cb60c79838d8d947708ce8/667934b23c723b61caf9888e_387835562_870604144539545_7581193837461794148_n-removebg-preview%201.png" loading="eager" alt="Oatobiasson" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://cdn.prod.website-files.com/60cb60c79838d8d947708ce8/667932b502cd433542c2d05b_6645ef4111c06538a791e22a_PROCAVA%20LOGO%20NEW%201.png" loading="eager" alt="Procava" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://cdn.prod.website-files.com/60cb60c79838d8d947708ce8/667932a8d6713bb366957959_6645ef9adcbe81af51f2def1_rugu%20bygg%20logga.svg" loading="eager" alt="Rugu Bygg" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/662b9ff0c128e969b0ede7f0_Tommy-Byggare_Vit%203.png" loading="eager" alt="Tommy byggare" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/661e408bad8bbbdac22a7941_659e7ad631caedad224cf95c_Hedin%20Construction%20Black%201.png" loading="eager" alt="Hedin Construction AB" className={styles.logo_grid_icon} />
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/65c0b535d5c2317db9c0ee15_Frame%20162.png" loading="eager" alt="T3 Group" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/65c4cf32683dfc75e9292e8d_goooo.png" loading="eager" alt="Inceptio AB" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/65bd00626748ae624ced9002_22222119.png" loading="eager" alt="Partnex AB" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/65ba6b1b1a3fac949422a7be_coforma11.png" loading="eager" alt="Coforma AB" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/65ba6a287ddd1a3699904bc2_Frame.svg" loading="eager" alt="Terranor Ab" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/6399ec2a5192791052581ffb_consto%201.png" loading="eager" alt="Consto" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/6399ec096063ad04b8368147_implenia.png" loading="eager" alt="Implenia" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/6377995887a3e691e2f9d46a_mta.png" loading="eager" alt="MTA Bygg" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/63613dd1a38243262a7daa85_nimab.png" loading="eager" alt="NIMAB ENTREPRENAD AB" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/633bf31ab6671c36f610e002_ylab.png" loading="eager" alt="YLAB" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/62c6ec0bd39757c84e94f0fd_Bonava_Logotype_LightGreen_RGB_0.png" loading="eager" alt="Bonava" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/6399eed6ec46d5ef48d5870c_emptor%20(1).png" loading="eager" alt="Emptor" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/628c91776242e9646048af6d_olofsson.png" loading="eager" alt="Olofssons bygg" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/628c9192db75083bea1d2df6_byggpartner.png" loading="eager" alt="ByggPartner" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/628c919a58483e18697aa7f5_kynningsrud.png" loading="eager" alt="Kynningsrud" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/628c9e3781d372b450780b8d_fo-peterson.png" loading="eager" alt="FOP" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/628c91adfd42531e26a52823_credentia.png" loading="eager" alt="Credentia" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/628c91b7dd3862ff9b087409_brixly.png" loading="eager" alt="Brixly logga" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/628c91c3f58c6983c89dd959_m2-bygg.png" loading="eager" alt="M3 loggan" className={styles.logo_grid_icon}/>
                      </div>
                      <div role="listitem" className={styles.c_logo_grid_icon_wrapper}>
                        <img src="https://assets-global.website-files.com/60cb60c79838d8d947708ce8/6411d48e13caaa878d6e3cab_628c91cabf2ac95810f8fbd1_subterra.png" loading="eager" alt="SBT Sverige" className={styles.logo_grid_icon}/>
                      </div>
                    </div>

                    <TooltipContainer renderReferenceComponent={(className, ref) => (
                      <div
                        className={className}
                        ref={ref}
                        style={{ width: 'fit-content', alignSelf: 'center', marginTop: '24px' }}
                      >
                        <BtButton
                          disabled={!appState.hasSupplierProfileBeenUpdated()}
                          onClick={() => {
                            setIsApplicationModalVisible(true);
                            setAdApplication({
                              errors: {},
                              startDate: moment().toDate(),
                              regions: [],
                              areas: [],
                              applicationType: AdApplicationType.RECOMMENDED,
                              invoiceInterval: undefined,
                              invoiceEmail: appState.getInvoiceEmail(),
                              invoiceRef: appState.getInvoiceRef(),
                            })
                          }}
                        >
                          Ansök om att bli en rekommenderad leverantör
                        </BtButton>
                      </div>
                    )}>
                  {!appState.hasSupplierProfileBeenUpdated() && <span>Fyll i <a href={`/${appState.getCurrentOrgSlug()}/supplier-presentation`} style={{ color: 'var(--gray-700)' }}>leverantörspresentationen</a> först.</span>}
                    </TooltipContainer>
                    <p style={{fontSize: "14px", color: "var(--gray-500)", marginTop: "32px", marginBottom: "-8px"}}>Obs! Accurator Prio inkluderar tjänsten Accurator Kontroll. Om du redan betalat för Accurator Kontroll kommer motsvarande summa dras av från priset för Accurator Prio på din faktura.</p>
                  </Card>
                )}
                {!!lastRecommendedApplication && (
                  <ApplicationStatusCard
                    isPending={isRecommendedApplicationPending}
                    applicationType={AdApplicationType.RECOMMENDED}
                    rejectionReason={lastRecommendedApplication?.rejectionReason ?? ''}
                  />
                )}

                {hasActiveRecommendedAd && (
                  <Card title="Resultat" className={styles.result}>
                    {!!adInfo?.addedSuppliers?.length ? (<>
                      <p>Du har lagts till i följande leverantörsdatabaser sedan annonsens början:</p>
                      <table style={{ width: "100%" }}>
                        <thead>
                          <th>Beställare</th>
                          <th>Datum</th>
                        </thead>
                        <tbody>
                          {adInfo?.addedSuppliers?.map(supplier => {
                            return (
                              <tr key={supplier.name}>
                                <td>{supplier.name}</td>
                                <td>{formatDate(supplier.createdAt)}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                      <p>Se även "Mottagna förfrågningar" i menyn till vänster för att se detaljer om förfrågningar och anbud.</p>
                    </>) : (
                      <p>Du har inte lagts till någon leverantörsdatabas sedan annonsens början. Se "Mottagna förfrågningar" i menyn till vänster för att se detaljer om förfrågningar och anbud.</p>
                    )}
                  </Card>
                )}
              </>
            )}
          </>
        )}
        {selectedTab === 1 && !loading && (
          <>
            {!adInfo?.isFeatured && !lastFeaturedApplication && (
              <Card className={styles.card}>
                <h3>Accurator Annons</h3>
                <p><b>Har du en tillfällig kampanj, luckor i fabriken eller en helt ny tjänst eller produkt du vill visa upp?</b></p>
                <p>Med Accurator Annons kan du enkelt och effektivt nå ut till rätt målgrupp utan att slösa tid och pengar på irrelevanta visningar.</p>
                <p>Vi hjälper dig att:</p>
                <ul role="list" className={styles.list}>
                  <li>Skapa en målgruppsanpassad annons som når relevanta bygginköpare.</li>
                  <li>Sätt din annons på Accurators startsida och få maximal exponering.</li>
                  <li>Få detaljerade rapporter om annonsens resultat.</li>
                </ul>
                <p>Med Accurator Annons slipper du betala för att nå ut till fel målgrupp. 
                  Vi garanterar att din annons visas för de personer som är mest benägna att vara intresserade av dina produkter 
                  eller tjänster.</p>
                <p>Klicka på knappen nedan och fyll i ansökan. Vi återkommer till dig inom kort.</p>
                <TooltipContainer renderReferenceComponent={(className, ref) => (
                  <div
                    className={className}
                    ref={ref}
                    style={{ width: 'fit-content', alignSelf: 'center', marginTop: '24px' }}
                  >
                    <BtButton
                      disabled={!appState.hasSupplierProfileBeenUpdated()}
                      onClick={() => {
                        setIsApplicationModalVisible(true);
                        setAdApplication({
                          errors: {},
                          startDate: moment().toDate(),
                          title: '',
                          description: '',
                          url: '',
                          image: undefined,
                          applicationType: AdApplicationType.FEATURED,
                          invoiceInterval: undefined,
                          invoiceEmail: appState.getInvoiceEmail(),
                          invoiceRef: appState.getInvoiceRef(),
                        })
                      }}
                    >
                      Ansök om att annonsera på startsidan
                    </BtButton>
                  </div>
                )}>
                  {!appState.hasSupplierProfileBeenUpdated() && <span>Fyll i <a href={`/${appState.getCurrentOrgSlug()}/supplier-presentation`} style={{ color: 'var(--gray-700)' }}>leverantörspresentationen</a> först.</span>}
                </TooltipContainer>
              </Card>
            )}

            {!!lastFeaturedApplication && (
              <ApplicationStatusCard
                isPending={isFeaturedApplicationPending}
                applicationType={AdApplicationType.FEATURED}
                rejectionReason={lastFeaturedApplication?.rejectionReason ?? ''}
              />
            )}

            {adInfo?.isFeatured && (
              <Card title='Aktiva annonser' className={styles.activeFeatureSupplierAd}>
                <div className={styles.activeRecommendedAdsItem}>
                  <p>Datum</p>
                  <span>{formatDate(adInfo?.featuredInfo?.startDate)} - {formatDate(adInfo?.featuredInfo?.endDate)}</span>
                </div>
                {(adInfo?.featuredInfo?.image) && (
                  <NewsCard
                    image={adInfo.featuredInfo.image}
                    title={adInfo.featuredInfo?.title ?? ''}
                    description={adInfo.featuredInfo?.description ?? ''}
                    link={adInfo.featuredInfo?.url}
                    adPreviewMode
                  />
                )}
              </Card>
            )}

            {adInfo?.isFeatured && adInfo?.featuredInfo?.startDate && adInfo?.featuredInfo?.startDate && (
              <TitleWrapper title="Statistik">
                <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                  <ReceivedBidRequestStats requests={requests} adStartDate={moment(adInfo?.featuredInfo?.startDate).toDate()} />
                </div>
              </TitleWrapper>
            )}

            {hasActiveFeaturedAd && (
              <Card title="" className={styles.result}>
                <p>Du har lagts till i följande leverantörsdatabaser sedan annonsens början:</p>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Beställare</th>
                      <th>Datum</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adInfo?.addedFeaturedSuppliers?.map(supplier => {
                      return (
                        <tr key={supplier.name}>
                          <td>{supplier.name}</td>
                          <td>{formatDate(supplier.createdAt)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Card>
            )}
          </>
        )}
        {adApplication && (
          <AdModal
            title="Ansökan"
            show={isApplicationModalVisible}
            setShow={(value) => setIsApplicationModalVisible(value) }
            adApplication={adApplication}
            allQuotationsSubscription={allQuotationsSubscription}
            setAdApplication={setAdApplication}
            submitFeaturedSupplierAd={submitFeaturedSupplierAd}
            submitRecommendedSupplierAd={submitRecommendedSupplierAd}
            submitting={submitting}
            setSubmitting={setSubmitting}
            areas={areas}
          />
        )}
      </div>
    </AppLayout>
  );
}

export default withRouter(PageMarketing);