import React, { Component, createContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { Provider, Subscribe } from 'unstated';
import { IntlProvider } from 'react-intl';
import 'nprogress/nprogress.css';
import appState, { AppStateContainer } from './state/AppStateContainer';
import Loading from './components/shared/Loading';
import PageBidRequestList from './ts-components/page-bid-request-list/page-bid-request-list';
import PageMarketing from './ts-components/page-marketing/page-marketing';
import PageDashboardV2 from './ts-components/page-dashboard-V2/page-dashboard-v2';
import PageHome from './ts-components/page-home/page-home';
import PageProjects from './ts-components/page-projects/page-projects';
import PageQuotation from './ts-components/page-quotation/page-quotation';
import PageFiles from './ts-components/page-files/page-files';
import PageSupplierPresentation from './ts-components/page-supplier-presentation/page-supplier-presentation';
import PageReports from './ts-components/page-reports/page-reports';
import RatingForm from './ts-components/rating-form/rating-form.jsx';
import InternetError from './ts-components/page-response-message/page-response-message-error.jsx';
import BrowserSupport from './ts-components/page-browser-support/page-browser-support.jsx';
import _ from 'lodash';
import messages from './localization/strings.json';
import PageLogin from './ts-components/page-login/page-login';
import PageChangePassword from './ts-components/page-password/page-change-password';
import PageSso from './ts-components/page-sso/page-sso';
import UserInvitation from './ts-components/page-registration/user-invitation';
import Signup from './ts-components/page-registration/page-signup';
import RotateGif from './assets/images/rotate.gif'
import { createTheme, ThemeProvider } from '@mui/material';
import { ServiceAuth } from './services/service-auth';
import appEnum, { PrequalificationDecision } from './util/appEnum';
import BtGrid from './ts-components/bt-grid/bt-grid';
import BtRow from './ts-components/bt-row/bt-row';
import BtColumn from './ts-components/bt-column/bt-column';
import { installInterceptors } from './services/axiosHelper';
import PageHelp from './ts-components/page-help/page-help';
import SupplierSignup from './ts-components/page-supplier-signup/page-supplier-signup';
import { ServiceSupplier } from './services/service-supplier';
import PageUpdates from './ts-components/page-updates/page-updates';
import SupplierSignUp from './ts-components/page-registration/page-supplier-signup';
import { PageContractStatus } from './ts-components/page-contract-status/page-contract-status';
import { PageSupplierMonitoring } from './ts-components/page-supplier-monitoring/page-supplier-monitoring';
import PagePublicBidRequestList from './ts-components/page-public-bid-request/page-public-bid-request-list';
import PageInvoices from './ts-components/page-invoices/page-invoices';
import PagePublicBidRequest from './ts-components/page-public-bid-request/page-public-bid-request';
import serviceSales from './services/service-sales';

installInterceptors();

function orgRoute({ component, path, exact, render, orgSlug="" }) {
  const result = [<Route component={component} path={`${orgSlug}${path}`} exact={exact} render={render} key="route" />];
  if (orgSlug && orgSlug !== "/") {
    result.push(<Redirect path={path} exact={exact} to={`${orgSlug}${window.location.pathname}`} key="redirect" />);
  }    
  return result;  
}
function protectedOrgRoute({ component, path, exact, notAllowedRoles = [], product, orgSlug="" }) {
  const result = [<ProtectedRoute component={component} path={`${orgSlug}${path}`} exact={exact} notAllowedRoles={notAllowedRoles} product={product} key="route" />];
  if (orgSlug && orgSlug !== "/") {
    result.push(<Redirect path={path} exact={exact} to={`${orgSlug}${window.location.pathname}`} key="redirect" />);
  }    
  return result;
}

function ProtectedRoute({ component, path, exact, notAllowedRoles = [], product }) {
  if (appState.state.token && !_.isNull(appState.state.token)) {
    if (      
      !notAllowedRoles.includes(appState.state.token.role) &&      
      !(product && !(appState.getActiveProducts().includes(product))) ||
      appState.isSuperAdminOrg()
    ) {
      return <Route path={path} component={component} exact={exact} />;
    } else {
      return <Redirect to={path.replace(path.split("/")[2], "dashboard")} />;
    }
  } else {
    return <Route component={PageLogin} />;
  }
}

class App extends Component {
  state = {
    appStateLoaded: false,
    orgSlugState: {},
    supplierProfileHasBeenUpdated: false,
  };
  orgSlug;
  async componentDidMount() {
    const [,orgSlug,url] = window.location.pathname.split("/");
    this.detectBrowser();
    if (url === 'invite') {
      appState.setState({loader: true});
      // const { slug, wasValid } = await appState.setCurrentOrgSlug();
      this.orgSlug = `/${orgSlug}`;
      this.setState({ orgSlugState: {slug: this.orgSlug, wasValid: true}, appStateLoaded: true });
      appState.setState({loader: false});
    } else if (orgSlug === 'sso') {
      appState.setState({loader: true, loaded: true});
      this.setState({appStateLoaded:true}); 
    } else {
      appState.setState({loader: true});
      await this.checkSupplierSession();
      await this.handleInitialMounting();
      if (appState.isAdminRole() && appState.isPurchasingActive()) {
        // Set initial supplier application count, will be updated when suppliers are fetched in supplier database
        const supplierApplications = await ServiceSupplier.fetchSupplier({ mini: true, prequalification: PrequalificationDecision.INBOX });
        appState.setSupplierApplicationCount(supplierApplications.inboxCount);      
      }
    }
    this.detectLanaguage();
    window.addEventListener("storage", this.onStorageUpdate);
  }

  checkSupplierSession = async () => {
    try {
      const orgSlug = appState.getOrgSlugFromUrl();
      let supplierSpecificRoute = appState.isSupplierSpecificRoute();
      if(supplierSpecificRoute){
        let encryptedLink = window.location.pathname.split('/').find(s => s.length >= 128);
        if (encryptedLink) await ServiceAuth.verifyAndGetSupplierToken(encryptedLink, orgSlug);
      }
    } catch(error) {
      console.log(error?.response)
    }
  };

  handleInitialMounting = async () => {
      const { slug, wasValid } = await appState.getCurrentOrgUrl();
      if (slug) {
        this.orgSlug = slug;
      }
      const user = await appState.getUserDetails(); //Checks for updated details on reload
      if (appState.isSalesActive()) {
        const supplierProfileHasBeenUpdated = await serviceSales.hasProfileBeenUpdated();
        appState.setSupplierProfileHasBeenUpdated(supplierProfileHasBeenUpdated);
      }
      this.setState({ orgSlugState: {slug, wasValid}, appStateLoaded: true });
      appState.setState({loader: false});
  }

  onStorageUpdate = ({newValue, key}) => {
    if(key === "token"){
      let newVal = newValue && newValue !== 'undefined' && newValue !== 'null' ? JSON.parse(newValue) : "";
      if(appState.state.token?.id !== newVal?.id){
        appState.setState({token: null});
      }
    }
  };

  componentWillUnmount(){
    window.removeEventListener("storage", this.onStorageUpdate);
  }

  detectLanaguage() {
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get('language');
    if (value === 'en') {
      appState.setLanguage('en');
    } else if (value === 'sv') {
      appState.setLanguage('sv');
    }
    // return value === "1" || window.location.host.indexOf("simple") >= 0;
  }

  detectBrowser() {
    const ua = navigator.userAgent;
    const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    this.setState({
      is_ie: is_ie,
    });
  }

  renderLoader() {
    return (
      <>
        <div
          className=""
          style={
            appState.state.loader
              ? { pointerEvents: 'none', zIndex: '9999', width: '100%', height: '100%' }
              : {}
          }
        >
          <div className={`app-loader ${appState.state.loader ? ' loader-show' : ''}`}>
            <span className="bounce1"></span>
            <span className="bounce2"></span>
            <span className="bounce3"></span>
          </div>
        </div>
        <NotificationContainer />
      </>
    );
  }


  _renderApp(appState) {
    if (appState.state.loaded && this.state.appStateLoaded) {
      const startPage = (() => {
        if (appState.isPurchasingActive()) {
          return PageDashboardV2
        }

        if (appState.isSalesActive()) {
          if (!appState.hasSupplierProfileBeenUpdated()) {
            return () => <Redirect to ={`${this.orgSlug}/supplier-presentation`}/>;
          } else {
            return () => <Redirect to={`${this.orgSlug}/requests`} />;
          }
        }
      })()
      const url = this.orgSlug;
      const orgSlug = this.orgSlug;
      return (
        <Router>
          <div>
            <section className={`body-wrapper`} id="content">
              {this.renderLoader()}
              {/* <div className="gui-blocker"></div> */}

              <div className="page-browser-support or-landscape-only">
                <BtGrid>
                  <BtRow>
                    <BtColumn>
                      <img src={RotateGif} alt="Rotate Your Device"/>
                      <h2 className="title">Turn Your Device</h2>
                      <p className="description">We do not support portrait mode.</p>
                    </BtColumn>
                  </BtRow>
                </BtGrid>
              </div>

              <Switch>
                {(
                  window.location.host === 'localhost:3000' ||
                  window.location.host === 'test.accurator.se' ||
                  window.location.host === 'app.accurator.se'
                ) && (
                  <Route path={`/supplier-sign-up`} exact={true} component={SupplierSignUp} />
                )}
                {(
                  window.location.host === 'localhost:3000' ||
                  window.location.host === 'test.accurator.se' ||
                  window.location.host === 'app.accurator.se'
                ) && (
                  <Route path={`/contract-status/:code`} exact={true} component={PageContractStatus} />
                )}
                <Route path={`/error`} exact={true} render={() => <InternetError />} />
                <Route path={`/login`} render={() => <PageLogin />} />
                <Route path={`/sso`} render={() => <PageSso />} />

                <Route
                  path={`/forgot/password/:verificationcode?`}
                  component={PageChangePassword}
                />

                <Route
                  path={`/browser-support`}
                  exact={true}
                  render={() => <BrowserSupport />}
                />

                {(appState.state.token && !_.isNull(appState.state.token)) && ['/', this.orgSlug, `${this.orgSlug}/`, `/${this.orgSlug}`, `/${this.orgSlug}/`].map((path, index) => {
                  return (
                    <Route
                      path={path}
                      key={index}
                      exact={true}
                      render={() => (
                        <Redirect to={`${this.orgSlug}/dashboard`} />
                      )}
                    />
                  )
                })}
                {protectedOrgRoute({ orgSlug, path: `/dashboard`, exact: true, component: startPage })}
                
                {/* Superadmin routes */}
                {protectedOrgRoute({ orgSlug, path: `/organizations`, component: PageProjects })}
                {protectedOrgRoute({ orgSlug, path: `/reports`, component: PageReports })}
                {protectedOrgRoute({ orgSlug, path: `/invoices`, component: PageInvoices })}


                {orgRoute({ orgSlug, path: `/quotation/info/:id`, render: () => <PageQuotation orgSlug={this.orgSlug.replace('/', '')} /> })}
                {orgRoute({ orgSlug, path: `/rating`, render: () => <RatingForm /> })}

                {/* Purchasing routes */}
                {protectedOrgRoute({ orgSlug, path: `/projects`, component: PageProjects, product: "PURCHASING" })}
                {protectedOrgRoute({ orgSlug, path: `/tasks`, component: PageProjects, notAllowedRoles: [appEnum.Role.Client], product: "PURCHASING" })}
                {protectedOrgRoute({ orgSlug, path: `/supplier-database`, component: PageProjects, notAllowedRoles: [appEnum.Role.Client], product: "PURCHASING" })}
                {protectedOrgRoute({ orgSlug, path: `/categories`, component: PageProjects, notAllowedRoles: [appEnum.Role.Client], product: "PURCHASING" })}
                {protectedOrgRoute({ orgSlug, path: `/supplier-monitoring`, component: PageSupplierMonitoring, notAllowedRoles: [appEnum.Role.Client], product: "PURCHASING" })}
                {protectedOrgRoute({ orgSlug, path: `/files`, component: PageFiles, product: "PURCHASING" })}
                
                {/* Sales routes */}
                {protectedOrgRoute({ orgSlug, path: `/requests`, component: PageBidRequestList, product: "SALES" })}
                {protectedOrgRoute({ orgSlug, path: `/published-requests/:orgId/:projectId/:areaId`, exact: true, component: PagePublicBidRequest, product: "SALES" })}
                {protectedOrgRoute({ orgSlug, path: `/published-requests`, component: PagePublicBidRequestList, product: "SALES" })}
                {protectedOrgRoute({ orgSlug, path: `/supplier-presentation`, component: PageSupplierPresentation, product: "SALES" })}
                {protectedOrgRoute({ orgSlug, path: `/marketing`, component: PageMarketing, product: "SALES" })}

                {/* Common routes */}
                {protectedOrgRoute({ orgSlug, path: `/chat`, component: PageProjects, notAllowedRoles: [appEnum.Role.Client] })}
                {protectedOrgRoute({ orgSlug, path: `/settings`, component: PageProjects, notAllowedRoles: [appEnum.Role.Client, appEnum.Role.Inköp, appEnum.Role.Kalkyl]})}

                {orgRoute({ orgSlug, path: `/forgot/password/:verificationcode?`, component: PageChangePassword })}
                {orgRoute({ orgSlug, path: `/change/password/`, component: PageChangePassword })}
                {orgRoute({ orgSlug, path: `/syna/report`, render: () => window.fetchingReport ? <Loading message="Hämtar rapport..." /> : null })}
                {orgRoute({ orgSlug, path: `/projects/:project/:area/tips`, component: PageProjects })}
                {orgRoute({ orgSlug, path: `/invite/:code?`, render: props => <UserInvitation {...props} /> })}
                {orgRoute({ orgSlug, path: `/signup`, render: props => <Signup {...props} /> })}
                {orgRoute({ orgSlug, path: `/new-supplier`, component: SupplierSignup })}
                {orgRoute({ orgSlug, path: `/profile`, render: props => <PageProjects {...props} /> })}
                {orgRoute({ orgSlug, path: `/help`, render: props => <PageHelp /> })}
                {orgRoute({ orgSlug, path: `/updates`, render: props => <PageUpdates /> })}

                {this.state.is_ie && <Route path={path} exact={true} render={() => <Redirect to="/browser-support" />} />}
                {(!appState.state.token || _.isNull(appState.state.token)) && <Route render={() => <PageHome/>} />}

                <Route render={() => <InternetError />} />
              </Switch>
            </section>
          </div>
        </Router>
      );
    } else {
      return (
        <div>
          <section className={`body-wrapper`} id="content">
            {this.renderLoader()}
          </section>
        </div>
      );
    }
  }

  render() {
    return (
      <Subscribe to={[AppStateContainer]}>
        {appState => (
          <IntlProvider
            locale={appState.state.language}
            messages={messages[appState.state.language]}
          >
            {this._renderApp(appState)}
          </IntlProvider>
        )}
      </Subscribe>
    );
  }
}

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export const BidRequestDraftsContext = createContext();

const WrappedApp = () => {
  const [bidRequestDrafts, setBidRequestDrafts] = useState({});

  return (
    <BidRequestDraftsContext.Provider value={[bidRequestDrafts, setBidRequestDrafts]}>
      <ThemeProvider theme={theme}>
        <Provider inject={[appState]}>
          <App />
        </Provider>
      </ThemeProvider>
    </BidRequestDraftsContext.Provider>
  )
};

export default WrappedApp;
