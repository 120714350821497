import React, { Fragment, useReducer } from 'react';
import { NavLink, Switch, Router, Route } from 'react-router-dom';
import { serviceProfile } from '../../services/service-profile';
import { btDirname } from '../../utils/bt-string';
import { btSort } from '../../utils/bt-array';
import { ServiceChat } from '../../services/service-chat';
import { ServiceProjects } from '../../services/service-projects';
import { ServiceContract } from '../../services/service-contract';
import { serviceAreas } from '../../services/service-areas';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import BtRoute from '../bt-route/bt-route';
import AppCreateProject from '../app-create-project/app-create-project';
import PageProjectsSingle from './page-projects-single';
import '../app-select-industry-types/app-select-industry-types.css';
import SupplierDatabase from '../app-supplier-database/supplier-database';
import { AppChatStart } from '../app-chat/app-chat-start';
import { PageInternalChat } from '../page-internal-chat/page-internal-chat';
import PageProjectInternList from './page-project-intern-list';
import PageProjectsSingleInbox from './page-projects-single-inbox';
import PageProjectsStart from './page-projects-start';
import AppChatMailBatch from '../app-chat-mail-batch/app-chat-mail-batch';
import { ServiceAuth } from '../../services/service-auth';
import AppAddSupplier from '../app-add-supplier/app-add-supplier';
import './page-projects.css';
import PropTypes from 'prop-types';
import ProjectStrategy from './project-strategy';
import _ from 'lodash';
import appState from '../../state/AppStateContainer';
import appEnum from '../../util/appEnum';
import commonHelper from '../../util/commonHelper';
import PageProjectUsersAreas from './page-project-users-areas';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import Organization from '../page-organization/page-organization';
import PageOrganizationApplications from '../page-organization/PageOrganizationApplications';
import PageTips from '../page-tips/page-tips';
import Modal from '../../components/shared/Modal/Modal';
import { ServiceSubCategory } from '../../services/service-sub-categories';
import { ServiceUser } from '../../services/service-user';
import Settings from '../page-settings/PageSettings';
import PageProfile from '../../ts-components/page-profile/PageProfile';
import PageSupplierSelection from '../../ts-components/page-supplier-selection/page-supplier-selection';
import PageInternalBidForm from '../../ts-components/page-internal-bid-form/page-internal-bid-form';
import moment from 'moment';
import PageBidRequest from '../page-bid-request/page-bid-request';
import PageTasks from '../page-tasks/page-tasks';
import AppLayout from '../../components/layout/app-layout/app-layout';
import Loading from '../../components/shared/Loading';
import BackButton from '../../components/shared/BackButton/BackButton';
import PageErfaRequest from '../page-erfa-request/page-erfa-request';
import PageScriveRequest from '../page-scrive-request/page-scrive-request';
import PageCategoryMapping from '../page-category-mapping/page-category-mapping';
import CategorySelection from './CategorySelection/CategorySelection';
import { MassMessagingModal } from './MassMessagingModal/MassMessagingModal';

const projectUrl = 'projects';

export class PageProjects extends React.Component {
  appPageProjectUsersAreasInstance;
  pagemounted = true;
  appCreateProject;
  supplierDatabase;
  modalAddMember;
  appAddMember;

  elIndustryType;

  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props, context) {
    super(props, context);
    const role = appState.getRoleFromUrlParams();
    this.state = {
      internOnlyRender: false,
      internOnly: false,
      renderTime: 0,
      updateFlag: false,
      projects: [],
      currentProject: null,
      modalCreateProject: true,
      reLoadSuplleirs: false,
      _colleagues: [],
      colleagues: [],
      editSupplierData: [],
      currentMember: null,
      role,
      modalPageProjectUsersAreasShown: false,
      allAreas: [],
      winningSupplier: [],
      credibilityData: null,

      showNewCategoryModal: false,
      areas: [],
      projectList: [],
      users: [],
      isCurrentProjectLoading: true,
      showCreateProjectModal: false,
      submittingArea: false,
      selectedCategories: [],
      creatingNewProject: false,
    };

    this.refreshCurrrentProject = this.refreshCurrrentProject.bind(this);
  }

  // checkHighRiskProject = id => {
  //   const { credibilityData } = this.state;
  //   if(credibilityData.projects && credibilityData.projects.length)
  //     return credibilityData.projects.findIndex(pr => pr === id) > -1;
  // }

  // checkHighRiskSupplier = (pId, aId, id) => {
  //   const { credibilityData } = this.state;
  //   const checkArea = this.checkHighRiskArea(pId, aId);
  //   if(checkArea && credibilityData.supData && credibilityData.supData.length)
  //     return credibilityData.supData.findIndex(sup => sup.supplier._id === id && sup.comp_data && sup.comp_data.highRiskBit) > -1;
  // }

  // checkHighRiskArea = (id, pId, suppliers = []) => {
  //   const { credibilityData } = this.state;
  //   const checkProject = this.checkHighRiskProject(pId);
  //   let checkSupplier;
  //   if(credibilityData.supData && credibilityData.supData.length){
  //     checkSupplier = suppliers.filter(o1 => credibilityData.supData.some(o2 => o1.supplierId._id === o2.supplier._id && o2.comp_data && o2.comp_data.highRiskBit));
  //   }
  //   if(checkSupplier && checkSupplier.length && checkProject && credibilityData.areas && credibilityData.areas.length)
  //     return credibilityData.areas.findIndex(ar => ar === id) > -1;
  // }
  componentWillUnmount() {
    this.pagemounted = false;
  }
 
  componentDidMount() {
    this.setState({ internOnly: true });

    this.startProccessing();
  }
  async startProccessing() {
    const socket = ServiceChat.createSocket(appState.getUserToken());
    socket.on(`chatmessage`, x => {
      this.subscriber(x);
    });

    try {
      let colleagues = await serviceProfile.getColleagues();
      const chatNotifications = await ServiceChat.getNotifications();
      colleagues.map(x => {
        var result = chatNotifications.filter(n => n.fromUserId == x._id);
        if (result.length > 0) {
          x.notificationCount = result[0].count;
        }
        return x;
      });
      const role = appState.getRoleFromUrlParams();

     const allAreas = await this.fetchAreas();

      const users = await ServiceUser.getTasksUsers();
      const people = users.map(user => ({ label: user.name, value: user._id, projects: user.projects, role: user.role, avatarUrl: user.avatarUrl }));
      this.setState({ users: people });
      // const credibilityData = await serviceCreditSafe.getCredibilityData();
    
     if(this.pagemounted) this.setState({ role, colleagues, allAreas /* credibilityData */ }, () => {
        if (this.isProjectScreen()) this.refreshCurrrentProject(true);
      });
    } catch (e) {
      console.error('error', e);
    }

    this.bind();
    this.initiateDropdown();

    document.removeEventListener('click', this.handleClick, true);
    document.addEventListener('click', this.handleClick, true);

    // Load all areas for area selector in the modal
    serviceAreas.getAreas().then(result => {
      const areas = result.map(area => {
        const subAreas = area.subAreas.map(subArea => this.formatSubAreaForSelector(subArea, area.name));
        return this.formatAreaForSelector({...area, subAreas});
      });
      this.setState({ areas });
    });
  }
  
  async fetchAreas() {
    const mainAreas = await serviceAreas.getAreas(false);
    return [...mainAreas, ...mainAreas.map(o => o.subAreas).flat()];
  }

  subscriber = async messageObject => {
    const pathName = this.props.location.pathname;
    const urlLength = pathName.split('/').length;
    // console.log(this.state.renderTime,this.state.internOnly,this.state.internOnlyRender,"sub runn")
    if (urlLength == 2 && this.state.renderTime < 1 && this.state.internOnly) {
      // console.log("subscriber---page-project")
      await ServiceChat.updateNotifications(messageObject.fromUserId);
      this.updateNotification(messageObject.fromUserId);
    }
  };

  setRenderTime = () => {
    this.state.renderTime = 0;
  };

  setInternOnly = () => {
    this.setState({
      internOnly: false,
      internOnlyRender: false,
    });
  };

  checkUrl = (str, strToSearch) => {
    const url = str.split('/');
    return url.find(e => e == strToSearch);
  };

  updateNotification = async id => {
    const { colleagues } = this.state;
    const pathName = this.props.location.pathname;
    const urlLength = pathName.split('/').length;
    if (id !== appState.state.token.id) {
      let i = -1;
      const checkIntern = await colleagues.find((c, _i) => {
        i = _i;
        return c._id == id;
      });
      if (i > -1 && !this.checkUrl(pathName, checkIntern.slug)) {
        // don't notify for opened chat
        this.state.renderTime++;
        if (!pathName.includes(checkIntern.slug) && urlLength == 2 && this.state.internOnly) {
          // check for renderTime when chat not selected
       if(this.pagemounted)   this.setState({ internOnlyRender: true });
        }
        if (this.state.renderTime == 1) {
          if (!colleagues[i].notificationCount) {
            colleagues[i].notificationCount = 1;
          } else {
            colleagues[i].notificationCount++;
          }
          if (this.internList) {
            this.internList.updateNotification(colleagues);
            if (urlLength > 2) {
              await ServiceChat.updateNotifications(id);
            }
            this.state.renderTime = 0;
            // if(urlLength == 2){
            //   this.state.renderTime = 0
            //  }
          }
        }
      }
    }
  };

  bind() {
    this.obsColleagues = serviceProfile.data$.subscribe(colleagues => {
      if (this.pagemounted) this.setState({ colleagues, __colleagues: colleagues });
    });

    this.obsProjects = ServiceProjects.data$.subscribe(projects => {
      if (this.pagemounted) this.setState({ projects });
    });
    this.obsCurrentProject = ServiceProjects.datacurrent$.subscribe(currentProject => {
      let winningSupplier = [];
      const projectAreas = currentProject.area
        ? currentProject.area
        : [];
      projectAreas.filter(r => {
        // console.log("ProjectArea: ",r)
        (r.step == 3 || r.step == 4) &&
          r.suppliers &&
          r.suppliers.filter(_ => {
            // console.log("supplier: ",_)
            if (_.reward) {
              _.areaName = r.areaId.name;
              _.areaId = r.areaId._id;
              winningSupplier.push(_);
            }
          });
      });
      if (this.pagemounted) this.setState({ currentProject, winningSupplier });
    });
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
    if (!this.state.isCurrentProjectLoading) {
      const pathname = this.context.router.history.location.pathname;
      let [, , , areaSlug] = this.getUrlComponents(pathname);
      if (areaSlug && pathname.endsWith(areaSlug)) {
        const card = document.getElementById("bidcard-" + areaSlug);
        console.log("card", card)
        if (card) {
          card.scrollIntoView();
          this.context.router.history.replace(pathname.substring(0, pathname.length - areaSlug.length - 1));
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
    var newPathName = nextProps.location.pathname;
    var oldPathName = this.props.location.pathname;

    // console.log('componentWillReceiveProps---')
    if (oldPathName != newPathName) {
      const role = appState.getRoleFromUrlParams();
      this.setState({ role });

      if (this.isProjectScreen(newPathName) && !this.isSameProject(oldPathName, newPathName)) {
        this.refreshCurrrentProject(true);
      }

      // scrollbar fix on chat open
      window.location.pathname.split('/').length >= 6
        ? $('.body-wrapper').addClass('conv--wrapper')
        : $('.body-wrapper').removeClass('conv--wrapper');
    }
  }

  initCounterEvent() {
    $('[data-counter]').on('click', e => {
      let $element = $(e.currentTarget);
      let $headerCounter = $element
        .parents('bt-accordion')
        .find('bt-accordion-head [data-counter]');
      let counter = parseInt($element.attr('data-counter'));
      let parentCounter = parseInt($headerCounter.attr('data-counter'));
      if (typeof parentCounter == Number && typeof counter == Number) {
        parentCounter = parentCounter - counter;
        counter = 0;
      }

      $element.attr('data-counter', counter);
      $headerCounter.attr('data-counter', parentCounter);
    });
  }

  parseCurrentStep(currentProject) {
    let currentStep = parseInt(currentProject && currentProject.currentStep);
    if (isNaN(currentStep)) currentStep = 1;

    return currentStep;
  }

  getCurrentStep(currentProject) {
    let currentStep = this.parseCurrentStep(currentProject);
    const maxStep = appState.getListViewBetaPreference() ? 7 : 6;
    if (currentStep > maxStep) currentStep = 2;

    currentStep = currentStep - 1; //-1 because stepZilla steps are 0 based index.

    return currentStep;
  }

  // TODO: need to see this
  clearNotification = notObj => {
    if (!notObj || !Object.keys(notObj).length) return;

    const { currentProject, projects } = this.state;
    const currentProjectAreas = currentProject && currentProject.area;

    if (!currentProjectAreas || !currentProjectAreas.length) return;

    const updatedAreas = currentProjectAreas.map(area => {
      const { areaId, suppliers } = area;

      if (areaId && areaId.notification && areaId.notification.length) {
        const areaIndex = areaId.notification.findIndex(
          el => el.supplierMemberId === notObj.supplierMemberId && el.areaId === notObj.areaId,
        );

        if (areaIndex >= 0) areaId.notification.splice(areaIndex, 1);
      }

      if (suppliers && suppliers.length) {
        for (let i = 0; i < suppliers.length; i++) {
          const sup = suppliers[i];
          const { supplierId } = sup;

          if (supplierId && supplierId.notification && supplierId.notification.length) {
            const supIndex = supplierId.notification.findIndex(
              el => el.supplierMemberId === notObj.supplierMemberId && el.areaId === notObj.areaId,
            );

            if (supIndex >= 0) supplierId.notification.splice(supIndex, 1);
          }
        }
      }

      return area;
    });

    const updatedProjects = projects.map(pr => {
      if ((pr.notification && pr.notification.length, pr._id === currentProject._id)) {
        const prIndex = pr.notification.findIndex(
          el => el.supplierMemberId === notObj.supplierMemberId,
        );

        if (prIndex >= 0) pr.notification.splice(prIndex, 1);
      }

      return pr;
    });

    this.setState({
      currentProject: { ...currentProject, area: updatedAreas },
      projects: updatedProjects,
    });
  };

  getCurrentSlide() {
    var id = 0;
    if (this.isHistoryChat()) {
      id = 2;
    } else if (this.historycurrentProject()) {
      id = 1;
    }
    return id;
  }

  elAddMailBatchClickCallback(areaSlug, version, copySource) {
    this.setState({
      currentAreaSlug: areaSlug,
      currentProject: { ...this.state.currentProject },
      version: version >= 0 ? version : null,
    });
    const projectSlug = this.state.currentProject.slug;
    const orgSlug = appState.getCurrentOrgSlug();
    let history = this.context.router.history;
    const sourceParam = copySource ?? ""

    history.push(`/${orgSlug}/projects/${projectSlug}/${areaSlug}/bid-request/${version >= 0 ? version + 1 : "new"}${sourceParam}`, {});
    // this.modalMailBatch.show = true;
  }

  // elAddMailBatchVersionClickCallback(areaSlug, version) {
  //   this.setState({
  //     currentAreaSlug: areaSlug,
  //     currentProject: this.state.currentProject,
  //     version: version,
  //   });
  //   this.modalMailBatch.show = true;
  // }

  handleMailBatch(quotation) {
    const areaSlug = this.state.currentAreaSlug;
    const currentProject = this.state.currentProject;
    const projectSlug = currentProject.slug;

    ServiceProjects.markQuoteSent(projectSlug, areaSlug, quotation);
    this.modalMailBatch.show = false;
    this.initiateDropdown();
    NotificationManager.success(
      this.props.intl.formatMessage({
        id: 'global.skickat',
        defaultMessage: 'Skickat',
      }),
    );
    this.setState({ updateFlag: !this.state.updateFlag });
  }

  historycurrentProject() {
    let [, , id] = this.getUrlComponents(this.context.router.history.location.pathname);
    return id;
  }

  isProjectScreen(newUrl = this.props.location.pathname) {
    const newUrlComponents = this.getUrlComponents(newUrl);
    return newUrlComponents[1] == projectUrl; //update check project path
  }

  isSameProject(oldUrl, newUrl) {
    const oldUrlComponents = this.getUrlComponents(oldUrl);
    const newUrlComponents = this.getUrlComponents(newUrl);
    if (
      oldUrlComponents[1] == projectUrl &&
      newUrlComponents[1] == projectUrl &&
      oldUrlComponents[2] == newUrlComponents[2]
    ) {
      return true;
    }

    return false;
  }

  getUrlComponents(url) {
    return !url
      ? []
      : url.split('/').filter(el => {
          return el != '';
        });
  }


  editProjectBtnCallback(currentProject) {
    this.setState({
      createDialogCurrentProject: currentProject,
    });
    this.setState({showCreateProjectModal: true});
  }

  // saveSupplier = (result, check) => {
  //   if(check){
  //     this.setState({ reLoadSuplleirs: true });
  //   }else{
  //     this.setState({ reLoadSuplleirs: true, modifySuppName: result.name });
  //   }
  // };

  isHistoryChat() {
    var s = this.context.router.history.location.pathname.split('/').filter(el => {
      return el != '';
    });
    let [, name] = s;
    if (name == 'chat') {
      return true;
    } else {
      return false;
    }
    // return false;
  }

  editMemberBtnCallback = async (currentMember) => {
    await this.getProjectList();
    this.setState({
      currentMember: currentMember,
      showModalAddMember: true,
    });
    // this.modalAddMember.show = true;
  }

   getProjectList = async () => {
    let projectList = await ServiceProjects.getProjectList();
    this.setState({ projectList })
  }

  handleAddMember(sender, member) {
    // this.modalAddMember.show = false;
    this.toggleAddMemberModal();
    // let history = this.context.router.history;
    // setTimeout(() => {
    //   history.push('/chat/' + btDirname(member.slug) + '/', {});
    // }, 1000);
  }

  toggleAddMemberModal = () => {   if(this.pagemounted) this.setState({ showModalAddMember: false });}

  handleProjectUpdateCreated(sender, project) {
    const orgSlug = appState.getCurrentOrgSlug();
    this.setState({showCreateProjectModal: false});
    let history = this.context.router.history;

    project.createdAt = new Date().getTime();
    ServiceProjects.setCurrent(project);

    setTimeout(() => {
      history.push(`/${orgSlug}/projects/${btDirname(project.slug)}`, {});
    }, 1000);
  }

  addProjectBtnCallback() {
    let showCount = this.state.createProjectShowCount || 0;
    this.setState({ createProjectShowCount: ++showCount, showCreateProjectModal: true });
  }

  closeCreateProjectModal = () => this.setState({showCreateProjectModal: false});

   addMemberBtnCallback = async () => {
     if(!this.state.showModalAddMember){
       if(this.appAddMember && this.appAddMember.reset) this.appAddMember.reset();  
       this.setState({
         currentMember: null,
         showModalAddMember: true,
       });
    await this.getProjectList();
  }
    // this.modalAddMember.show = true;
  };

  btnAddSupplierClick(selectedArea) {
    let history = this.context.router.history;
    console.log(`/${appState.getCurrentOrgSlug()}/projects/${this.state.currentProject.slug}/${selectedArea.areaId.slug}/supplier-selection`);
    history.push(`/${appState.getCurrentOrgSlug()}/projects/${this.state.currentProject.slug}/${selectedArea.areaId.slug}/supplier-selection`, {});
  }

  saveSuppliersHandler = async (sender, supplierIds, bidData) => {
    try {
      let currentProject = this.state.currentProject;
      const area = currentProject.area.find(area => area.areaId.slug === window.location.pathname.split('/')[4]);
      let projectSlug = currentProject.slug;
      let areaId = area.areaId._id;
      let areaSlug = area.areaId.slug;
      let suppliers = bidData && bidData.suppliers && JSON.parse(bidData.suppliers);

      this.setState({ showSupplierSavingLoading: true });
      const response = await ServiceProjects.dbAddSuppliers(
        projectSlug,
        areaId,
        supplierIds,
        bidData,
      );

      if (bidData && bidData.extendedContactPersons && suppliers) {
        const expDate = new Date(bidData.quotationExpiryDate);
        // Update expiry only when quotation is expired
        if(moment().isAfter(expDate) && suppliers?.length > 0){
          suppliers.map(sup =>
            ServiceProjects.changeSupplierDate(projectSlug, areaSlug, sup.supplierId, expDate),
          );
        }
      }

      this.setState({
        showSupplierSavingLoading: false,
        showSupplierDatabaseModal: false,
      });

      let areaSuppliers = response.suppliers.map(ss => ({ supplierId: ss }));

      ServiceProjects.replaceAreaSuppliersBySlug(projectSlug, areaId, areaSuppliers);
    } catch (error) {
      console.log('error-----', error);
      this.setState({
        showSupplierSavingLoading: false,
        showSupplierDatabaseModal: false,
      });
    }

    let history = this.context.router.history;
    history.goBack();
  };

  updateEditSupplierData(data) {
    let formData = {
      city: [],
      name: '',
      supplierId: '',
      areaIds: [],
      organizationNumber: '',
    };

    if (data.length != 0) {
      formData.kommun = { label: data.kommun, value: data.kommun };
      formData.name = data.name;
      formData.city = data.city;
      formData.organizationNumber = data.organizationNumber;
      formData.vatNumber = data.vatNumber;
      formData.website = data.website;
      formData.address = data.address;
      formData.isBlacklist = data.isBlacklist ? data.isBlacklist : false;
      formData.blacklistReason = data.blacklistReason ? data.blacklistReason : [];
      formData.address = data.address;
      formData.postnumber = data.postnumber;
      formData.supplierId = data._id;
      let areaIds = [];
      data.areaIds.forEach(function (row, key) {
        areaIds.push(row._id);
      });
      formData.areaIds = areaIds;
    }

    this.setState({ editSupplierData: formData });
  }

  handleStrategyUpdated(sender, newStrategy) {
    let currentProject = this.state.currentProject;
    let projectSlug = currentProject.slug;

    ServiceProjects.replaceStrategyBySlug(projectSlug, newStrategy);

    NotificationManager.success(
      this.props.intl.formatMessage({
        id: 'global.strategySuccess',
        defaultMessage: 'Strategin sparad.',
      }),
      this.props.intl.formatMessage({
        id: 'global.success',
        defaultMessage: 'Sparat',
      }),
    );
  }

  async validateNewStep(sender, oldStep, newStep, isCheckClicked) {
    let currentProject = this.state.currentProject;
    if (!currentProject) return true;

    let maxStep = this.parseCurrentStep(currentProject) - 1;
    let projectSlug = currentProject.slug;

    if (maxStep <= 4 && newStep == maxStep + 1 && isCheckClicked && appState.isManagementRole()) {
      await ServiceProjects.dbUpdateProjectCuurentStep(projectSlug, newStep + 1);
      if(this.pagemounted)    this.setState({
        currentStep: newStep,
      });
      let newMaxStep = maxStep + 2; //+2 because maxStep is 0 based and we want it to be incremented.

      //AA, 16-NOV-2019, Not using async-await pattern here as the calling function needs a true/false return value.
      ServiceProjects.dbUpdateProjectStep(projectSlug, newMaxStep).then(resp => {
        ServiceProjects.replaceStepBySlug(projectSlug, newMaxStep);

        if (newMaxStep <= 6) {
          setTimeout(() => {
         if(this.pagemounted)   this.setState({ currentStep: newMaxStep - 1 });
          }, 300);
        }
      });
      // }
    } else {
      await ServiceProjects.dbUpdateProjectCuurentStep(projectSlug, newStep + 1);
      if(this.pagemounted)  this.setState({
        currentStep: newStep,
      });
      return newStep <= maxStep;
    }

    return false;
  }

  toggleModalPageProjectUsersAreas = async () => {
    this.setState({ showNewCategoryModal: true, selectedCategories: [] });
  };

  onErfa = e => {
    const history = this.context.router.history;
    const projectSlug = this.state.currentProject.slug;
    const orgSlug = appState.getCurrentOrgSlug();

    history.push(`/${orgSlug}/projects/${projectSlug}/erfa`);
  }

  contractUploadHandler = (sender, area) => {
    const history = this.context.router.history;
    const projectSlug = this.state.currentProject.slug;
    const orgSlug = appState.getCurrentOrgSlug();
    console.log(sender, area, "area")
    history.push(`/${orgSlug}/projects/${projectSlug}/${area?.areaId?.slug}/scrive`);
  }

  // handleSearchUsers(e) {
  //   const keyword = e.target.value;
  //   // cl
  //   let data = this.state.__colleagues;
  //   if (keyword) {
  //     data = data.filter(item => item.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1);
  //   }
  //   this.setState({
  //     colleagues: data,
  //   });
  // }

  handleSearchProjects(e) {
    const keyword = e.target.value;
    let data = ServiceProjects.storage;
    if (keyword) {
      data = data.filter(item => item.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1);
    }

    this.setState({
      projects: data,
    });
  }

  getBackUrl() {
    let path = '/projects';
    let currentPath = this.context.router.history.location.pathname.split('/');
    if (currentPath.length > 4) {
      if (currentPath[1] == 'projects') {
        path = '/' + currentPath[1] + '/' + currentPath[2] + '/' + currentPath[3];
      }
    }
    return path;
  }

  async removeArea(e) {
    const id = e.target.getAttribute('data-id');
    const name = e.target.getAttribute('data-name');
    const message = `Är du säker på att du vill radera ${name}?`;
    if (window.confirm(message)) {
      await ServiceProjects.removeProjectArea(this.state.currentProject.slug, id);
      this.refreshCurrrentProject();
    }
  }

  async removeProject(projectId, projectName, projectSlug) {
    if (window.confirm('Är du säker på att du vill radera detta projekt ' + projectName + '?')) {
      await ServiceProjects.deleteProject(projectSlug);
      ServiceProjects.remove(projectId);
      return true;
    }
    return false;
  }

  async clearNotifications(projectId, projectName) {
    if (window.confirm('Markera alla meddelanden i ' + projectName + ' som lästa? Detta går inte att ångra.')) {
      await ServiceProjects.clearNotifications(projectId);
      await appState.refreshProjectNotifications();
      return true;
    }
    return false;
  }

  async clearAllNotifications() {
    if (window.confirm('Markera alla meddelanden i alla projekt som lästa? Detta går inte att ångra.')) {
      await ServiceProjects.clearAllNotifications();
      await appState.refreshProjectNotifications();
      return true;
    }
    return false;
  }

  initiateDropdown() {
    $('.js-toggle-dropdown').click(e => {
      e.stopImmediatePropagation();
      const toToggle = $(e.currentTarget).data('toggle');
      const hasSubmenu = $(e.currentTarget).attr('data-hasSubmenu');
      if (hasSubmenu == 'true') {
        $(toToggle).toggleClass('js-open');
        $(e.currentTarget).toggleClass('is--open');
      } else {
        $(toToggle).addClass('js-open');
        $(toToggle).addClass('is--open');
      }
      if ($(e.currentTarget).data('block-ui')) {
        $(toToggle).parent().append('<div class="gui-blocker"></div>');
        document.getElementsByClassName('gui-blocker')[0].classList.add('open');
      }
    });
  }

  changedPasswordScreen() {
    this.props.history.push({
      pathname: '/change/password',
      state: { changePassword: true },
    });
  }

  async logoutClickCallback() {
    await ServiceAuth.logout();
    window.location.href = '/';
  }

  checkRole(role, project) {
    if (this.state.role !== appEnum.Role.Supplier && role == appEnum.Role.Supplier) {
      this.setState({
        role: role,
      });
    }
    if (project) {
      this.setState({
        currentProject: project,
      });
    }
  }

  setCurrentData(payload) {
    this.setState({
      payload: payload,
    });
  }

  getCurrentProject(currentProject, step) {
    if (currentProject) {
      const area = currentProject.area.filter(f => f.step == step);
      currentProject.area = area;
      return currentProject;
    }
  }

  toggleProjectStatus = async e => {
    let slug = this.state.currentProject.slug;
    const status = this.state.currentProject.isOpen ? false : true;

    await ServiceProjects.updateProjectOpenStatus(slug, status);
    ServiceProjects.replaceIsOpenBySlug(slug, status);

    NotificationManager.success(
      <FormattedMessage
        id="global.supplierContractor"
        defaultMessage="Statusen har blivit uppdaterad."
      />,
      <FormattedMessage id="global.success" defaultMessage="Ändring utförd" />,
    );
  };

  renderWizard({match}) {
    const { currentProject, currentStep } = this.state;
    const look = "new";
    const setLook = async look => {
      await ServiceUser.updatePreferences(
        appState.getUserId(), 
        { enabled: look == "new" }, 
        appEnum.userPrefrences.listViewBeta
      );
      await appState.getUserDetails();
    }

    let projectSlug = currentProject && currentProject.slug;
    const areaSlug = match.params.area;
    let maxStep = this.parseCurrentStep(currentProject);
    const strategy = currentProject && currentProject.strategy;
    const setCurrentProject = (project) => this.setState({currentProject: project});
    const steps = [
      {
        name: 'Strategi',
        step: 0,
        component: (
          <ProjectStrategy
            onStepChange={this.handleAccordionStepChange}
            currentProject={currentProject}
            setCurrentProject={setCurrentProject}
            projectSlug={projectSlug}
            strategy={strategy}
            editProjectBtnCallback={this.editProjectBtnCallback.bind(this)}
            onStrategyUpdated={this.handleStrategyUpdated.bind(this)}
            onNewArea={this.toggleModalPageProjectUsersAreas}
            onErfa={this.onErfa}
            onToggleProjectStatus={this.toggleProjectStatus}
            look={look}
            setLook={setLook}
            history={this.props.history}
          />
        ),
      },
      {
        name: 'Inköpsplan',
        step: 6,
        component: (
          <PageProjectsSingle
            onStepChange={this.handleAccordionStepChange}
            currentProject={currentProject}
            setCurrentProject={setCurrentProject}
            step={6}
            allowEditing={true}
            isWinnerEditable={true}
            editProjectBtnCallback={this.editProjectBtnCallback.bind(this)}
            isContractSigning={true}
            contractUploadEvent={this.contractUploadHandler.bind(this)}
            refreshCurrentProject={this.refreshCurrrentProject}
            onNewArea={this.toggleModalPageProjectUsersAreas}
            onErfa={this.onErfa}
            onToggleProjectStatus={this.toggleProjectStatus}
            users={this.state.users}
            addSupplier={this.btnAddSupplierClick.bind(this)}
            createNewVersion={this.elAddMailBatchClickCallback.bind(this)}
            selectedArea={areaSlug}
            look={look}
            setLook={setLook}
            history={this.props.history}
          />
        ),
      },
      {
        name: 'Förfrågan',
        step: 1,
        component: (
          <PageProjectsSingle
            onStepChange={this.handleAccordionStepChange}
            currentProject={currentProject}
            setCurrentProject={setCurrentProject}
            step={1}
            allowEditing={true}
            isWinnerEditable={false}
            editProjectBtnCallback={this.editProjectBtnCallback.bind(this)}
            refreshCurrentProject={this.refreshCurrrentProject}
            onNewArea={this.toggleModalPageProjectUsersAreas}
            onErfa={this.onErfa}
            onToggleProjectStatus={this.toggleProjectStatus}
            users={this.state.users}
            addSupplier={this.btnAddSupplierClick.bind(this)}
            createNewVersion={this.elAddMailBatchClickCallback.bind(this)}
            selectedArea={areaSlug}
            look={look}
            setLook={setLook}
            history={this.props.history}
          />
        ),
      },
      {
        name: 'Utvärdering',
        step: 2,
        component: (
          <PageProjectsSingle
            onStepChange={this.handleAccordionStepChange}
            currentProject={currentProject}
            setCurrentProject={setCurrentProject}
            step={2}
            allowEditing={true}
            isWinnerEditable={true}
            editProjectBtnCallback={this.editProjectBtnCallback.bind(this)}
            onNewArea={this.toggleModalPageProjectUsersAreas}
            onErfa={this.onErfa}
            onToggleProjectStatus={this.toggleProjectStatus}
            refreshCurrentProject={this.refreshCurrrentProject}
            users={this.state.users}
            addSupplier={this.btnAddSupplierClick.bind(this)}
            createNewVersion={this.elAddMailBatchClickCallback.bind(this)}
            selectedArea={areaSlug}
            look={look}
            setLook={setLook}
            history={this.props.history}
          />
        ),
      },
      {
        name: 'Förhandling',
        step: 3,
        component: (
          <PageProjectsSingle
            onStepChange={this.handleAccordionStepChange}
            currentProject={currentProject}
            setCurrentProject={setCurrentProject}
            lastStep={true}
            allowEditing={true}
            isContractSigning={true}
            step={3}
            editProjectBtnCallback={this.editProjectBtnCallback.bind(this)}
            contractUploadEvent={this.contractUploadHandler.bind(this)}
            onNewArea={this.toggleModalPageProjectUsersAreas}
            onErfa={this.onErfa}
            onToggleProjectStatus={this.toggleProjectStatus}
            refreshCurrentProject={this.refreshCurrrentProject}
            users={this.state.users}
            addSupplier={this.btnAddSupplierClick.bind(this)}
            createNewVersion={this.elAddMailBatchClickCallback.bind(this)}
            selectedArea={areaSlug}
            look={look}
            setLook={setLook}
            history={this.props.history}
          />
        ),
      },
      {
        name: 'Avtal',
        step: 4,
        component: (
          <PageProjectsSingle
            onStepChange={this.handleAccordionStepChange}
            currentProject={currentProject}
            setCurrentProject={setCurrentProject}
            lastStep={true}
            allowEditing={true}
            isContractSigning={true}
            step={4}
            editProjectBtnCallback={this.editProjectBtnCallback.bind(this)}
            contractUploadEvent={this.contractUploadHandler.bind(this)}
            onNewArea={this.toggleModalPageProjectUsersAreas}
            onErfa={this.onErfa}
            onToggleProjectStatus={this.toggleProjectStatus}
            refreshCurrentProject={this.refreshCurrrentProject}
            users={this.state.users}
            addSupplier={this.btnAddSupplierClick.bind(this)}
            createNewVersion={this.elAddMailBatchClickCallback.bind(this)}
            selectedArea={areaSlug}
            look={look}
            setLook={setLook}
            history={this.props.history}
          />
        ),
      },
      {
        name: 'Information',
        step: 5,
        component: (<PageProjectsSingle
          onStepChange={this.handleAccordionStepChange}
          currentProject={currentProject}
          setCurrentProject={setCurrentProject}
          lastStep={true}
          allowEditing={true}
          isContractSigning={true}
          step={5}
          editProjectBtnCallback={this.editProjectBtnCallback.bind(this)}
          contractUploadEvent={this.contractUploadHandler.bind(this)}
          onNewArea={this.toggleModalPageProjectUsersAreas}
          onErfa={this.onErfa}
          onToggleProjectStatus={this.toggleProjectStatus}
          users={this.state.users}
          selectedArea={areaSlug}
          look={look}
          setLook={setLook}
          history={this.props.history}
          />)
      }
    ];
    if (currentStep !== undefined && steps.find(x => x.step === currentStep)) {
      return steps.find(x => x.step === currentStep).component;
    } else {
      if (currentProject) {
        return steps[0].component;
      }
      return <div></div>;
    }
  }

  handleAccordionStepChange = _newStep => {
    this.validateNewStep(null, null, _newStep, true);
    const { history } = this.context.router;
    const locationArray = history.location.pathname.split('/').filter(n => n);
    const baseLocation = locationArray.filter((el, i) => i < 3).join('/');

    console.log("handle accordion step change")
    if (locationArray.length > 2 && !appState.getListViewBetaPreference()) {
      console.log("pushing ", baseLocation);
      history.push(`/${baseLocation}`);
    }
  };

  renderContent() {
    const {
      showSupplierSavingLoading,
      showSupplierDatabaseModal,
      currentProject,
      areas
    } = this.state;
    let browserHistory = this.context.router.history;
    const url = appState._getCurrentOrgUrl();

    return (
      <Router history={browserHistory}>
        <Switch>
          <Route exact path={`${url}/projects`} render={() => (
              <PageProjectsStart 
                history={browserHistory}
                projects={this.state.projects}
                addProject={this.addProjectBtnCallback.bind(this)}
                removeProject={this.removeProject.bind(this)}
                clearNotifications={this.clearNotifications.bind(this)}
                clearAllNotifications={this.clearAllNotifications.bind(this)}
              />
            )} 
          />
          <Route path={`${url}/tasks`} exact component={PageTasks} />
          <Route path={`${url}/projects/:project/:areaSlug/tips`} exact component={PageTips} />
          <Route
            path={`${url}/organizations/applications`}
            render={() => <PageOrganizationApplications intl={this.props.intl} />}
          />
          <Route
            path={`${url}/organizations`}
            render={() => <Organization intl={this.props.intl} />}
          />
          <Route
            path={`${url}/supplier-database`}
            render={() => (
              <SupplierDatabase
                history={browserHistory}
                modifySuppName={this.state.modifySuppName}
                updateEditSupplierData={this.updateEditSupplierData.bind(this)}
                editSupplierData={this.state.editSupplierData}
                reLoadSuplleirs={this.state.reLoadSuplleirs}
                supplierDatabase={this.supplierDatabase}
                intl={this.props.intl}
              />
            )}
          />
          <Route
            path={`${url}/categories/:slug`}
            render={props => {
              return (
                <PageCategoryMapping
                  {...props}
                  category={props.match.params.slug}
                />
              )
            }}
          />
          <Route
            path={`${url}/chat/:id`}
            render={props => (
              <PageInternalChat
                internOnlyRender={this.state.internOnlyRender}
                internOnly={this.state.internOnly}
                updateNotification={this.updateNotification}
                {...props}
                editMemberBtnCallback={this.editMemberBtnCallback}
              />
            )}
          />
          <Route path={`${url}/chat`} render={props => (<PageProjectInternList intl={this.props.intl} {...props} />)} />

          <Route path={`${url}/settings`} render={() => <Settings />} />
          <Route path={`${url}/profile`} render={() => <PageProfile />} />
          {!this.state.isCurrentProjectLoading ?
            (<Switch>
                <Route
                  exact
                  path={`${url}/projects/:project/erfa`}
                  render={(props) => <PageErfaRequest currentProject={currentProject} {...props} />}
                />
                <Route
                  exact
                  path={`${url}/projects/:project/:areaSlug/scrive`}
                  render={(props) => <PageScriveRequest currentProject={currentProject} {...props} />}
                />
                <Route
                  exact
                  path={`${url}/projects/:project/:areaSlug/manual-contract`}
                  render={(props) => <PageScriveRequest currentProject={currentProject} {...props} />}
                />
                <Route
                  exact
                  path={`${url}/projects/:project/:areaSlug/bid-request/:version`}
                  render={(props) => <PageBidRequest currentProject={currentProject} {...props} />}
                />
                <Route
                  exact
                  path={`${url}/projects/:project/:areaSlug/bid-forms/:supplierSlug`}
                  component={PageInternalBidForm}
                />
                <Route
                  exact
                  path={`${url}/projects/:project/:areaSlug/supplier-selection`}
                  render={({match}) => (
                    <PageSupplierSelection
                      match={match}
                      areas={areas}
                      currentProject={currentProject}
                      onSaveClick={this.saveSuppliersHandler}
                      showLoading={showSupplierSavingLoading}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${url}/projects/:project/:type/:supplier/:supplierMember?`}
                  render={props => {
                    return (
                      <PageProjectsSingleInbox
                        {...props}
                        checkRole={this.checkRole.bind(this)}
                        setCurrentData={this.setCurrentData.bind(this)}
                        role={this.state.role}
                        currentProject={currentProject}
                        clearNotification={this.clearNotification}
                      />
                    );
                  }}
                />
                <Route path={`${url}/projects/:project/:area?`} render={this.renderWizard.bind(this)} />
              </Switch>)
           : (
            <AppLayout
            title={
               <BackButton 
                path={["..."]}
                onClick={() => history.go(`${url}/projects`)}
               />}
               >
     
            <div style={{display:"flex", width:"100%", height:"100%", justifyContent:"center", alignItems:"center"}}><Loading type="inline" /></div>
            </AppLayout>
          )}
        </Switch>
      </Router>
    );
  }

  async refreshCurrrentProject(initialLoadProjectFromList, showLoader = true) {
    var projectSlug = this.historycurrentProject();
    if (!projectSlug) return;

    let currentProject = this.state.currentProject;
    if (initialLoadProjectFromList) {
      currentProject = ServiceProjects.getProjectBySlug(projectSlug);
    }

    if(this.pagemounted && showLoader)  this.setState({ isCurrentProjectLoading: true, currentProject });
    let projects = await ServiceProjects.getProject(projectSlug);
    if (projects && projects.length > 0) {
      currentProject = projects[0];
    } else {
      currentProject = null;
    }

    let currentStep = this.getCurrentStep(currentProject);
    if(this.pagemounted) {
      this.setState(
        {
          currentProject,
          currentStep,
          isCurrentProjectLoading: false,
        },
        () => {
          this.initiateDropdown();
        },
      );
    }
    ServiceProjects.setCurrent(currentProject);
  }

  getMainArea = item => {
    const { allAreas } = this.state;
    const mainArea = allAreas.find(area => area.subAreas.some(sa => sa._id === item.areaId._id));
    return mainArea || {};
  };

  addArea = async (selectedArea) => {
    let slug = this.state.currentProject.slug;
    const areaId = selectedArea.areaId._id;

    await ServiceProjects.dbAddArea(slug, areaId);
  };

  formatAreaForSelector = (area) => {
    return { _id: area._id, label: area.name, value: area.slug, subAreas: area.subAreas, __isNew__: area.__isNew__ }
  }
  formatSubAreaForSelector = (subArea, areaName) => {
    return {_id: subArea._id, label: subArea.name.replace(new RegExp(`-${areaName}$`), ''), value: subArea.slug, __isNew__: subArea.__isNew__};
  }

  render() {
    // console.log(this.state.internOnly,"---internOnly")
    const {
      currentProject,
      role,
      version,
      currentAreaSlug,
      createDialogCurrentProject,
      createProjectShowCount,
      showNewCategoryModal,
      areas,
      selectedCategories,
    } = this.state;
    let currentProjectAreas = currentProject && currentProject.area;
    const isSupplier = role == appEnum.Role.Supplier;

    const onCreateProject = () => {
      this.appCreateProject?.btnCreateProjectClickCallback()
    }
    const onSaveHandler = async () => {
      this.setState({ submittingArea: true });

      try {
        const formattedSelectedCategories = selectedCategories.map(selectedCategory => {
          return {
            mainCategory: {
              _id: selectedCategory.mainCategory._id,
              name: selectedCategory.mainCategory.label,
            },
            subCategory: {
              _id: selectedCategory.subCategory?._id,
              name: selectedCategory.subCategory?.label,
              isNew: selectedCategory.subCategory?.__isNew__,
            }
          }
        })
        await serviceAreas.addAreaBatch(this.state.currentProject.slug, formattedSelectedCategories);

        await this.refreshCurrrentProject();
        const allAreas = await serviceAreas.getAreas(true);
        const tempAreas = this.state.areas.map(area => {
          const subAreas = allAreas.find(_area => _area._id === area._id)?.subAreas;
          return {
            ...area,
            subAreas: subAreas.map(subArea => this.formatSubAreaForSelector(subArea, area.label)),
          }
        });
        this.setState({
          areas: tempAreas,
          allAreas,
          showNewCategoryModal: false,
          submittingArea: false,
        });
      } catch (e) {
        this.setState({
          submittingArea: false,
        })
      }
    }

    const shouldSaveButtonBeDisabled = () => {
      return selectedCategories.some(selectedCategory => {
        const mainAreaSelected = selectedCategory?.mainCategory?.label;
        const subAreaSelected = selectedCategory?.subCategory?.label;
        const mainAreaIsSelectedInProject = mainAreaSelected && currentProjectAreas?.find(area => area.areaId.name === mainAreaSelected);
        const mainAreaAndSubAreaCombinationAlreadyExistsOnSelectedCategories = mainAreaSelected && selectedCategories.find(_selectedCategory =>
          _selectedCategory.id !== selectedCategory.id &&
          _selectedCategory.mainCategory?.value === selectedCategory.mainCategory?.value &&
          _selectedCategory.subCategory?.value === selectedCategory.subCategory?.value
        );
        return !mainAreaSelected || (mainAreaIsSelectedInProject && !subAreaSelected) || mainAreaAndSubAreaCombinationAlreadyExistsOnSelectedCategories;
      })
    }

    return (
      <div className={`page-projects ${isSupplier ? 'only--quotation' : ''}`}>
        {this.renderContent()}

        <Modal 
          title={createDialogCurrentProject?._id ? 'Redigera projekt' : 'Nytt projekt'} 
          show={this.state.showCreateProjectModal}
          buttonInfo={{
            label: createDialogCurrentProject?._id ? 'Spara' : 'Skapa',
            action: onCreateProject,
            loaderShow: this.appCreateProject?.state.loaderShow,
          }}
          setShow={this.closeCreateProjectModal}
        >
          <AppCreateProject
            ref={el => (this.appCreateProject = el)}
            currentProject={createDialogCurrentProject}
            showCount={createProjectShowCount}
            onProjectUpdateCreated={this.handleProjectUpdateCreated.bind(this)}
          />
        </Modal>

        <Modal
          title='Ny upphandling'
          show={showNewCategoryModal}
          setShow={(value) => this.setState({ showNewCategoryModal: value })}
          buttonInfo={{label: 'Skapa', disabled: shouldSaveButtonBeDisabled(), action: onSaveHandler, loaderShow: this.state.submittingArea}}
        >
          <CategorySelection
            selectedCategories={selectedCategories}
            setSelectedCategories={(getNewState) => this.setState((prevState) => ({
              ...prevState,
              selectedCategories: getNewState(prevState.selectedCategories)
            }))}
            currentProjectAreas={currentProjectAreas}
            areas={areas}
          />
        </Modal>
        <MassMessagingModal projectId={this.state.currentProject?._id} />
      </div>
    );
  }
}

export default injectIntl(PageProjects);
